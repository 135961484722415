import Album from "../../views/Album";
import Navbar from "../../components/Navbar/Navbar";
import { BrowserRouter } from "react-router-dom";
import { useState } from "react";
import { Alert } from "react";

const photos = [];

let numberOfImages = 9;

for (let i = 1; i <= numberOfImages; i++) {
    const img = require(`../../media/photos/img${i}.jpeg`);
    photos.push({ src: img, id: i});
}


const Home = () => {
    return ( 
        <div>
            <Album photos={photos} />
        </div>
     );
}
 
export default Home;